import { useState, useEffect, useRef } from "react";
import Link from "next/link";
import PopularSearches from "components/PopularSearches";
import InlineRecaptchaSignage from "components/RecaptchaSignage/Inline";
import styles from './footer.module.css'

import logExternalLinkClick from "lib/ga/externalLinkClick";
import logInternalLinkClick from "lib/ga/internalLinkClick";

const Expandable = ({ isOpen, holdOpen, children, id }) => {
  const expandableRef = useRef(null);
  useEffect(() => {
    let element = document.getElementById(id);
   if(element) {
    if (isOpen) {
      element.style.height = `${element.scrollHeight + 30}px`;
      element.style.paddingLeft = '0.5rem'
      element.style.paddingTop = '0.25rem'
      element.style.paddingBottom = '0.25rem'
    } else {
      element.style.height = '0'
      element.style.paddingLeft = '0'
      element.style.paddingTop = '0'
      element.style.paddingBottom = '0'
    }
   }
  }, [isOpen, id]);

return (
  <div className={styles.expandable} ref={expandableRef} id={id}>{children}</div>
)
};

type LinkProps = {
  href: string;
  as?: string;
  location: string;
  label: string;
};

const FooterInternalLink = ({ href, as, location, label }: LinkProps) => (
  <Link href={href} prefetch={false} passHref legacyBehavior>
    <a className={styles.footerLink} onClick={() => logInternalLinkClick(location, as ?? '')} aria-label={label} title={label}>
      {label}
    </a>
  </Link>
);

const FooterExternalLink = ({ href, location, label }) => (
  <a
  className={styles.footerHref}
    href={href}
    onClick={() => logExternalLinkClick(location, href, undefined, undefined, undefined)}
    target="_blank"
    rel="noopener noreferrer"
    aria-label={label}
    title={label}
  >
    {label}
  </a>
);

const Footer = ({ isApplicationWrapper = false, showPopular = true, homePage = false }) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [linksOpen, setLinksOpen] = useState(true);
  const [contactsOpen, setContactsOpen] = useState(true);
  const [groupOpen, setGroupOpen] = useState(true);
  const [newsOpen, setNewsOpen] = useState(true);
  const [holdOpen, setHoldOpen] = useState(true);

  const updateWindowDimensions = () => {
    setHoldOpen(window.innerWidth >= 768);
    if (window.innerWidth >= 768 && !linksOpen) setLinksOpen(true);
    if (window.innerWidth >= 768 && !contactsOpen) setContactsOpen(true);
    if (window.innerWidth >= 768 && !groupOpen) setGroupOpen(true);
    if (window.innerWidth >= 768 && !newsOpen) setNewsOpen(true);
  };

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      updateWindowDimensions();
    }

    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  });

  const LoadCookies = () => {
    let cookie = document.getElementById('cookie');
    if(cookie) {
      if(cookie.classList.contains('visiableCookie')) {
        cookie.classList.remove('visiableCookie');
        cookie.classList.add('displayNoneCookie')
      } else {
       cookie.classList.remove('displayNoneCookie');
       setTimeout(() => {
        cookie.classList.add('visiableCookie')
      }, 100)
      }
    }
  }

  return (
    <>
      {showPopular && (
        <div className={styles.PopularWrapper}>
           <div className={styles.wrapperPopular}>
           {!isApplicationWrapper && (
              <PopularSearches homePage={homePage} />

            )}
           </div>
        </div>
      )

      }
      <footer className={styles.FooterFluid}>

        {(!isApplicationWrapper) && (

          <div className={styles.primaryWrapper}>
            <div className={styles.footerInfo}>
              <div className={styles.footerInfoLink}>
                <Link href="/" passHref prefetch={false} legacyBehavior>
                  <a onClick={() => logInternalLinkClick(`/`, "Footer CR Logo")} title="Completely Retail homepage">
                  <img
                      src={`${process.env.NEXT_PUBLIC_APP_CDN_URL}icons/CR-logo-reduced.svg`}
                      height="50"
                      width="430"
                      alt="Completely Retail: The UK's no.1 place to find shops to rent"
                    />

                  </a>
                </Link>
              </div>
              {/* <FooterInfoText>The one truth for <strong>UK retail property</strong></FooterInfoText> */}
              <div className={`${styles.social} ${styles.footerInforSocial}`}>
                <a
                  href="https://twitter.com/CR_Tweets"
                  onClick={() => logExternalLinkClick("Footer Social Links", "https://twitter.com/CR_Tweets" )}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.footerInfoSocailLink}
                >
                  <img
                    src={`${process.env.NEXT_PUBLIC_APP_CDN_URL}icons/social-twitter-white.svg`}
                    alt="The Completely Group on Twitter"
                    width="30"
                    height="35"
                    loading="lazy" decoding="async"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/completelyretail.co.uk/"
                  onClick={() =>
                    logExternalLinkClick("Footer Social Links", "https://www.linkedin.com/company/completelyretail.co.uk/")
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.footerInfoSocailLink}
                >
                   <img
                    src={`${process.env.NEXT_PUBLIC_APP_CDN_URL}icons/social-linkedin-white.svg`}
                    alt="The Completely Group on LinkedIn"
                    width="30"
                    height="35"
                    loading="lazy" decoding="async"
                  />

                </a>
              </div>
            </div>

            <div className={styles.footerlinks}>
              <p className={linksOpen ? `${styles.footerLinksHeading} ${styles.isOpen}` : styles.footerLinksHeading } onClick={() => setLinksOpen(holdOpen ? true : !linksOpen)}>
                Additional Links
                <span>
                  <img src={`${process.env.NEXT_PUBLIC_APP_CDN_URL}icons/scrolltotop-white.svg`} loading="lazy" decoding="async" alt="^" width='11' height='14' />
                </span>
              </p>
              <Expandable isOpen={linksOpen} holdOpen={holdOpen} id='AddLinks'>
                <FooterInternalLink href="/portfolios" location="Footer Additional Links" label="View Portfolios" />
                {/* Disabled due to redundant nature of page */}
                {/* <FooterInternalLink href="/shops-to-rent" location="Footer Additional Links" label="View Shops to Rent" /> */}
                <FooterInternalLink
                  href="/new-shops-to-rent"
                  location="Footer Additional Links"
                  label="View Latest Properties"
                />
              </Expandable>
            </div>

            <div className={styles.footerContacts}>
              <p className={contactsOpen ? `${styles.footerLinksHeading} ${styles.isOpen}` : styles.footerLinksHeading } onClick={() => setContactsOpen(holdOpen ? true : !contactsOpen)}>
                Useful Links
                <span>
                  <img src={`${process.env.NEXT_PUBLIC_APP_CDN_URL}icons/scrolltotop-white.svg`} loading="lazy" decoding="async" alt="^" width='11' height='14' />
                </span>
              </p>
              <Expandable isOpen={contactsOpen} holdOpen={holdOpen} id='UseLinks'>
                <FooterInternalLink
                  href="/agent-pricing"
                  location="Footer Useful Links"
                  label="Listing packages for agents"
                />
                <FooterInternalLink href="/contact" location="Footer Useful Links" label="Contact Us" />
                {/*<FooterInternalLink href="/destinations" location="Footer Useful Links" label="Popular Destinations" />*/}
                {/* Pages were deleted by someone so these links are disabled for now */}
                <FooterInternalLink
                  href="/shops-to-rent-by-town"
                  location="Footer Useful Links"
                  label="Browse all properties by town or city"
                />
                <FooterInternalLink
                  href="/retail-schemes-by-town"
                  location="Footer Useful Links"
                  label="Browse all schemes by town or city"
                />
                 <FooterExternalLink
                  href="https://news.completelyretail.co.uk/category/news/"
                  location="Footer News Links"
                  label="Latest retail property news"
                />
              </Expandable>
            </div>

            {/* <div className="footerGroup">
              <div className={groupOpen ? "footerLinksHeading isOpen" : "footerLinksHeading" }  onClick={() => setGroupOpen(holdOpen ? true : !groupOpen)}>
                The Completely Group
                <span>
                  <img src={`${process.env.NEXT_PUBLIC_APP_CDN_URL}icons/scrolltotop-white.svg`} loading="lazy" decoding="async" alt="^" width='11' height='14' />
                </span>
              </div>
              <Expandable isOpen={groupOpen} holdOpen={holdOpen} id='completelyGroup'>
                <FooterExternalLink
                  href="https://completelyevents.com/"
                  location="Footer Sister Links"
                  label="Completely Events"
                />
                <FooterExternalLink
                  href="https://www.crmarketplace.com/events/london/"
                  location="Footer Sister Links"
                  label="CR Marketplace events"
                />
                <FooterExternalLink
                  href="https://www.crmarketplace.com/soapbox"
                  location="Footer Sister Links"
                  label="CRMP Soapbox"
                />
                <FooterExternalLink
                  href="https://news.completelyretail.co.uk/"
                  location="Footer News Links"
                  label="Completely Retail News"
                />
              </Expandable>
            </div> */}
          </div>
        )}

        <div className={styles.secondaryWrapper}>
          <a
            className={styles.footerPowered}
            href="https://completelygroup.com/"
            onClick={() => logExternalLinkClick("Footer Designed By", "https://completelygroup.com/", undefined, undefined, undefined)}
            target="_blank"
            rel="noopener noreferrer"
          >
            &copy; {new Date().getFullYear()} Completely Property Technology Limited part of The Completely Group
            Limited.
          </a>

          {!isApplicationWrapper && (

            <div className={styles.footerNav}>
              <span className={styles.footerFakeRef} onClick={() => {
                LoadCookies();
              }}>Update my cookie settings</span>
              <FooterExternalLink
                href="https://completelygroup.com/privacy"
                location="Footer Legal Links"
                label="Privacy Policy"
              />
              <FooterInternalLink
                href="/legal/terms-and-conditions/completely-retail"
                location="Footer Legal Links"
                label="Terms and Conditions"
              />
            </div>
          )}
        </div>
        {!isApplicationWrapper && (
          <div className={styles.tertiaryWrapper}>
            <InlineRecaptchaSignage />
          </div>

        )}
      </footer>
    </>
  );
};

export default Footer;
